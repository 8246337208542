














































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_I18N_LANGUAGES, SAVE_I18N_LANGUAGES } from '@/store/employee/actions.type'
import { I18n } from '@/store/employee/types'
import DashboardCollapse from '@/components/dashboard/DashboardCollapse.vue'
import VJsoneditor from 'v-jsoneditor'
import { AxiosResponse } from 'axios'
import { SimpleServerResponse } from '@/store/types'
import InfoBox from '@/components/hszg/InfoBox.vue'
import MetaComponent from '@/common/MetaComponent.vue'
Vue.component('v-jsoneditor', VJsoneditor)

@Component({
  components: { InfoBox, DashboardCollapse }
})
export default class LanguageManagement extends MetaComponent {
@Action(GET_I18N_LANGUAGES)
public getLanguages: () => Promise<Array<I18n>>

@Action(SAVE_I18N_LANGUAGES)
public saveLanguages: (languages: Array<I18n>) => Promise<AxiosResponse<SimpleServerResponse>>

  i18nLanguages: Array<I18n> = []
  loaded = false

  loadData (): void {
    this.$root.$emit('load')
    this.loaded = true
    this.getLanguages().then(data => {
      this.i18nLanguages = data.map(lang => ({ id: lang.id, lang: lang.lang, messages: lang.messages ? JSON.parse(lang.messages) : '' }))
    }).finally(() => {
      this.loaded = true
      this.$root.$emit('end-load')
    })
  }

  save (): void {
    let feedbackHeadline = ''
    let feedbackMessage = ''
    let responseState = true
    this.i18nLanguages = this.i18nLanguages.map(lang => ({ ...lang, messages: JSON.stringify(lang.messages) }))
    this.$root.$emit('load')
    this.saveLanguages(this.i18nLanguages).then(() => {
      this.loadData()
      feedbackMessage = ''
      feedbackHeadline = this.$t('info.save-success', { what: this.$t('main-menu-hszg.language-management') }).toString()
      responseState = true
    }).catch(error => {
      feedbackHeadline = this.$i18n.t('warning.error!').toString()
      feedbackMessage = error.message
      responseState = false
    }).finally(() => {
      this.$root.$emit('end-load')
      this.$root.$emit('alert', feedbackHeadline, feedbackMessage, !responseState)
    })
  }

  mounted (): void {
    this.loadData()
  }
}
