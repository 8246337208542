






















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
// Dashboard component which enables the collapse of the dashboard modules. Use the slot to implement the modules
// @group DASHBOARD
export default class DashboardCollapse extends Vue {
  // unique identifier for validity (and tests)
  @Prop({ required: true })
  id: string

  // if collapsible is expanded (visible) or not
  @Prop({ default: true })
  visible: boolean

  // title, which acts as the button to trigger the collapse
  @Prop({ required: true })
  title: string

  isVisible = true

  mounted (): void {
    this.isVisible = this.visible
  }
}
