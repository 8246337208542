









































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
/*
@group HSZG
A hszg-style infobox which can optionally have an icon and is used for hints.
The content is provided via the default slot.
*/
export default class InfoBox extends Vue {
  // optional icon (material icons)
  @Prop() icon?: string

  // if the infobox is dismissable (has a button to hide)
  @Prop({ default: false }) dismissable?: boolean

  visible = true

  get colSize (): number {
    let size = 12
    if (this.icon) size--
    if (this.dismissable) size--
    return size
  }
}
